// SCSS for Basic styling

h1{
    font-size: 5em;
    color: var(--color-white);
    word-break: break-word;
}
h2{
    font-size: 3em;
    word-break: break-word;
}
h3{
    font-size: 3em;
    word-break: break-word;
}
h4{
    font-size: 2em;
    word-break: break-word;
}
h5{
    font-size: 1.5em;
    word-break: break-word;
}

hr{
    max-width: 10em;
    min-width: 1em;
    border-color: var( --color-primary );
    border-width: 0.5em;
    margin: 2em auto;
    opacity: 1;
    border-radius: 1em;
}