// Animations to be shared

// Shake
@keyframes shake{
    0% { transform: translate( 0, 0 ); }
    10% { transform: translate( 0.03em, 0.02em ); }
    20% { transform: translate( -0.04em, 0.01em ); }
    30% { transform: translate( 0.01em, -0.02em ); }
    40% { transform: translate( 0.02em, 0.02em ); }
    50% { transform: translate( -0.01em, 0em ); }
    60% { transform: translate( 0.02em, 0.01em ); }
    70% { transform: translate( -0.03em, 0.02em ); }
    80% { transform: translate( 0.04em, -0.02em ); }
    90% { transform: translate( 0.02em, 0.02em ); }
    100% { transform: translate( 0, 0 ); }

}

// Spin
@keyframes spin{
    0%{ transform: rotate( 0deg ) };
    100%{ transform: rotate( 360deg ) };
}

// Rotate Around
@keyframes rotateAround {
    0%{ transform: rotate( 0deg ) }
    50%{ transform: rotate( 360deg ) }
    100%{ transform: rotate( 0deg ) }
}

// Randbow
@keyframes colorRainbow { 
    0% { fill: red; }
    20% { fill: orange; }
    40% { fill: yellow; }  
    60% { fill: green; }   
    80% { fill: blue; }   
    100% { fill: blueviolet; }  
}