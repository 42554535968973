// Settings SCSS

// The work
.settings{
    z-index: 100;
    position: fixed;
    bottom: 1em;
    right: 1em;

    max-width: 400px;

    // Gear icon
    .gear{
        position: absolute;
        right: 0;
        bottom: 0;

        &:hover{
            transform-origin: center;
            animation-name: spin;

        }

    }
    
    // Default view
    .card{
        box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);

        // Don't show by default
        visibility: visible;
        transform: scale(0);
        opacity: 0;
        filter: blur( 1em );
        
        transition: 0.2s ease-out;
        transform-origin: bottom right;

        .card-title{
            padding: 0.5em;
        }
        .card-text{
            padding: 1em;
        }

    }

    // Show settings modal
    &.true{
        .gear{
            display: none;

        }

        .card{
            visibility: visible;
            transform: scale( 1 );
            opacity: 1;
            filter: blur(0);

        }
    }

}