// Infinite Scroll SCSS

// Variables
$width: 250px;
$animationSpeed: 50s;
$companyCount: 10;
$xsScreen: 576px;

// Animation
@keyframes scroll {
	0% { transform: translateX( 0 ); }
	100% { transform: translateX( calc( -1 * $width * ( $companyCount / 2 ) ) ) }
}

// Styling
.slider {
	padding: 1em;
	overflow: hidden;
	border: solid #adadad 0.1em;

	background-color: #fff;

	@media screen and ( max-width: $xsScreen ) {
		max-width: calc( 100vw );
	}
		
	.slide-track {
		animation: scroll;
        animation-duration: $animationSpeed;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;

		display: flex;
		align-items: center;
		width: calc( $width * $companyCount );
	}
	
	.slide {
        margin-left: 3em;
        margin-right: 3em;
		
		vertical-align: middle;
	}

	img{
		width: $width;

	}
}