// 90's style

// Variables
.oldSchool{
    --color-white: #fff;
    --color-black: #000;
    --color-grey: #939393;

    --color-grey-light: #d6d6d6;
    --color-grey-dark: #4d4d4d;

    --color-link-blue: #0000EE;
    
    --color-btn: var( --color-grey );
    --color-btn-light: var( --color-grey-light );
    --color-btn-dark: var( --color-grey-dark );
    --color-btn-text: var( --color-link-blue );    

    --color-primary: #49297e;
    --color-primary-text: #fff;

    --color-highlight: #00db96;

}

// Animation
@keyframes shuffle {
    0% { transform: translate( 0, 0 ) };
    50% { transform: translate( -100px, 0 ) };
    100% { transform: translate( 100px, 0 ) };
}

// The stuff
.oldSchool{
    font-family: "Times New Roman", Times, serif;
    background-color: #000;

    //////////////////////////
    //////  Basic HTML  //////
    //////////////////////////
    
    p{
        background-color: #fff;
        color: #000;
    }
    
    // Horizontal line
    hr{
        border-radius: 0;
        box-shadow: 5px 5px 5px #fff;

    }

    // Headers
    h1, h2, h3, h4, h5{
        color: var( --color-white );
        background-color: var( --color-grey-dark );

        font-family: "Comic Sans MS", "Comic Sans", cursive;
        text-align: left;
        text-transform: uppercase;

        position: relative;

        padding-left: 50px;

        &:after{
            content: ' ';
            background-image: url( "../assets/images/spinning-star.gif" );
            background-size: 50px 100%;
            background-repeat: no-repeat;

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

        }

    }

    h3{
        &:after{
            content: '';
            background-image: url( "../assets/images/hand-right.gif" );
            background-size: 1em 50%;
            top: 30%;

        }

    }

    h4, h5{
        &:after{
            content: '';
            background-image: url( "../assets/images/gif-earth.gif" );
            background-size: 1.1em 1.1em;
            left: 0.1em;
            top: 0.1em;

        }

    }

    //////////////////////////
    // Bootstrap restyling  //
    //////////////////////////

    // Modal
    .modal{
        border-radius: 0;

        .modal-content{
            border-radius: 0;

            .modal-header{
                border-radius: 0;

            }

        }

    }

    // Give all columns a border
    .col{
        border: inset;
        box-shadow: 5px 5px 5px #000;
    }

    // Align it all left!
    .justify-content-md-center{
        justify-content: start !important;
    }

    // Nav - main
    #nav{
        &.bg-body-tertiary{
            background-color: var( --color-primary ) !important;
            color: var( --color-primary-text );

        }

        a{
            color: var( --color-primary-text );
        }
    }    

    // Nav - Tabs
    .nav-tabs{
        border-radius: 0;

        .nav-item{
            border-radius: 0;
            background-color: var( --color-link-blue );

            .nav-link{
                font-weight: bolder;

                background-color: var( --color-btn );
                color: var( --color-link-blue );
                border-radius: 0;
                border: outset 5px #000;
                text-transform: uppercase;

                &:hover{
                    background-color: var( --color-btn-light );
                    color: var( --color-btn-text );

                    transition-duration: 0s;
                    border-style: inset;
                }

                &.disabled{
                    color: var( --color-btn-light );
                    display: none;

                }

            }

        }

    }

    // Text align left
    .text-start{
        background-color: #fff;
    }

    // Cards
    .card{
        .card-title{
            background-color: var( --color-primary );
            text-transform: uppercase;
        }
    }

    // Buttons
    .btn{
        color: var( --color-btn-text );
        text-decoration: underline;

        background-color: var( --color-btn-light );
        border-radius: 0;
        border: outset 0.5em var( --color-btn );

        animation: none;
        transition-duration: 0s;

        &:hover{
            background-color: var( --color-btn );
            border-style: inset;

            color: var( --color-btn-text );

        }

    }
    
    /////////////////////
    /// Page specific
    ////////////////////
    
    // Backgrounds
    .bg-{
        &fullScreen{
            display: none;
        }

        &dino{
            background-image: url("../assets/images/space.jpg");
            background-repeat: repeat;
            background-size: 300px;
        }

        &primary-light{
            background-image: url("../assets/images/oldSchool-alt.png");
            background-repeat: repeat;

        }

    }

    // Main body of site
    #main{
        background-image: url("../assets/images/bg-oldSchool-dark.png");
        background-repeat: repeat;
        background-size: 750px;

    }

    // Homepage
    #Home{
        // horizontal rule
        hr{
            animation: shuffle;
            animation-duration: 5s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-direction: alternate-reverse;

        }

        // Dustin Text
        .dustinreesetext{
            border: solid 1px #fff;
            padding: 1em;
            background-color: #000;

            margin: 1em;

            h1{
                background-color: inherit;
            }
            
            svg{
                path{
                    animation: colorRainbow 2s infinite alternate-reverse;

                }
                
            }
        }

    }

    // About page
    #About{
        background-image: url("../assets/images/oldSchool-alt.png");
        background-repeat: repeat;

        .fullPage{
            background-color: var( --color-highlight );
    
        }

    }

    // Services page
    #Services{
        background-image: url("../assets/images/bg-oldSchool2.png");
        background-repeat: repeat;
        background-size: 600px;

        .fullPage{
            background-color: var( --color-highlight );
    
        }

    }
    
    // Resume
    #Resume{
        .card-body{
            hr{
                animation: none;
                margin-left: 0;
                margin-right: 0;
                max-width: 100%;

            }

        }
        
    }

    // Quest page
    #Quest{
        h1, h2, h3, h4, h5{
            text-align: left;
        }

        .animal-help{
            background-color: #fff;
            border: inset 5px;
            border-radius: 0;

        }

        .mission{
            background-image: none;
            background-color: #000;
            color: #fff;
            border-radius: 0;
            border: inset 5px;

            p{
                background-color: inherit;
                color: inherit;
            }

        }

        .status{
            .body{                
                background-color: #fff;
                border: inset 5px;
                border-radius: 0;

                h1, h2, h3, h4, h5{
                    text-align: left;
                }

                .tracker{
                    border-radius: 0;
                    border-color: var( --color-primary );

                    .status{
                        border-radius: 0;

                    }
                }

            }
        }
        
    }

    // Previous Clients
    #PreviousPartners{
        // Quotes
        .quotes{
            border-radius: 0;
            border-style: outset;

            &:before{
                content: " ";
            }

            &:after{
                content: "";

                position: absolute;

                bottom: 0;
                right: 0;
                width: 100px;
                height: 100px;

                z-index: 10;

                background-image: url( "../assets/images/word-art-cool.gif" );
                background-size: 150px 150px;
                background-position: -30px -29px;
                background-color: #ffffff;

                border: outset 5px #000;

            }

            .body{
                border-radius: 0;

                background-color: var( --color-highlight );

                background-image: url( "../assets/images/word-art-quote.png" );
                background-position: center;
                background-blend-mode: lighten;

                p{
                    background-color: #fff;
                    border: outset 4px #000;
                    padding: 1em;
                }

                .author{
                    text-align: left;
                    background-color: var( --color-primary );
                    color: var( --color-primary-text );

                    display: flex;
                    flex-direction: row;

                    .name{
                        margin-right: 1em;

                    }

                }

            }

        }

        // Word art
        .word_art{
            // Word Art - Amazing
            &0{
                &:after{
                    background-image: url( "../assets/images/word-art-amazing.gif" );
                    background-size: 97px 132px;
                    background-position: -4px -17px;

                }
            }

            // Word Art - Radical
            &1{
                &:after{
                    background-image: url( "../assets/images/word-art-radical.gif" );
                    background-size: 97px 132px;
                    background-position: -5px -23px;

                }
            }

            // Word Art - Wow
            &2{
                &:after{
                    background-image: url( "../assets/images/word-art-wow.gif" );
                    background-size: 100px 132px;
                    background-position: -4px -24px;

                }
            }

        }

    }

    // Contact page
    #Contact{
        background-image: url("../assets/images/bg-oldSchool2.png");
        background-repeat: repeat;
        background-size: 600px;

        .fullPage{
            background-color: var( --color-highlight );
    
        }

    }

}