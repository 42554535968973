// Quest Icon

// Variables
.questIcon{
    --bg-radius: 0.1em;
    --bg-stroke: 0.1em;
    --bg-width: 100%;
    --bg-height: 100%;

    --bg-color: #77fdfd;

}

// Mixin
@mixin bgShape( $rotate: 0deg ) {
    content: " ";

    position: absolute;
    width: var( --bg-width );
    height: var( --bg-height );
    border-radius: var( --bg-radius );

    background-color: var( --bg-color );
    transform: rotate( $rotate );
    transform-origin: center;
    transition: 0.5s;

}

///////////////

// Animation 

// Shadow
@keyframes shadow-pulse{
    0% { filter: drop-shadow( 0px 0px 0.2em var( --color-action ) ); }
    100% { filter: drop-shadow( 0px 0px 0.5em var( --color-secondary ) ); }
}

// Quest Icon stuff
.questIcon{
    margin-top: 1em;
    margin-bottom: 1em;

    font-size: 3em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 1.5em;

    position: relative;

    // Utility - Animation
    .shake{
        animation-name: shake;
        animation-iteration-count: infinite;
        animation-duration: 10s;

    }

    // Background
    .bg1{
        background-color: #bebebe;
    }
    .bg2{
        background-color: #bebebe;
    }
    .bg3{
        background-color: #bebebe;
    }

    
    .fa-layers{
        width: 100%;
    }

    .text{
        z-index: 10;
        font-size: 0.2em;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        padding: 0.3em;
    }

    // Click me!
    &.clickMe{
        z-index: 10;
        cursor: pointer;

        animation: 2s infinite ease-in-out alternate-reverse shadow-pulse;
        
        .text{
            background-color: var( --color-action );
            font-weight: bold;
            border: solid 0.2em #000;
            border-radius: 1em;

        }

        .fa-cookie-bite{
            color: #6d1818;
            filter: drop-shadow( 2px 2px 0 #fff );
        }

        .bg1{
            @include bgShape( 0 );

        }
        .bg2{
            @include bgShape( -30deg );
        }
        .bg3{
            @include bgShape( 30deg );
        }

        &:hover{
            .text{
                background-color: var( --color-action-btn-hover-bg );
                color: var( --color-action-btn-hover-color )
            }

            .fa-cookie-bite{
                filter: drop-shadow( 2px 2px 0 #000 );
                
            }

            .bg1{
                transform: scale( 1.3 );
                background-color: var( --color-primary );
            }
            .bg2{
                background-color: var( --color-white );
                transform: rotate( 0 ) scale( 0.8 );

                animation: 5s ease-in-out spin infinite forwards;
            }
            .bg3{
                background-color: var( --color-white );
                transform: rotate( 0 ) scale( 0.8 );

                animation: 5s ease-in-out spin infinite reverse;
            }            
            
        }

    }

    .fa-{
        &check{
            color: green;
            filter: drop-shadow( 2px 2px 0 #ffffff );

        }

        &cookie-bite{
            color: #6d1818; 
            transition: 0.5s;

        }
    }

}