// Quest page styles

// Variables
#Quest{
    --sign-color: #ffc7ac;
    --sign-color-dark: #816658;

}

// Animation
@keyframes sad{
    0% { filter: drop-shadow( 0px 0px 0.2em var( --color-action ) ); transform: rotate( -45deg );  opacity: 0; }
    100% { filter: drop-shadow( 0px 0px 0.5em var( --color-secondary ) ); transform: rotate( 45deg ) scale( 2 ); color: blue; opacity: 0.1 ; }

}

// Main ID
#Quest{
    overflow: hidden;

    .sad{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;

        padding: 3em;

        text-align: center;

        animation: sad, shake;
        animation-iteration-count: infinite;
        animation-duration: 1s;
        animation-direction: alternate-reverse;
        animation-timing-function: ease-in-out;     
        
        svg{
            height: 50%;
        }

    }

    // Before //
    .mission{
        position: relative;

        border: solid 0.8em var( --sign-color-dark );
        border-radius: 1em;
        padding: 1em;
        margin-bottom: 1em;
        background-color: rgb(255, 226, 188);

        background-color: var( --sign-color );
        background-image: url("../assets/images/wood-pattern.png");

        &:after{
            content: " ";
            z-index: -1;

            background-color: var( --sign-color-dark );
            height: 50em;
            width: 1em;

            bottom: -50em;
            left: calc( 50% - 1em );

            position: absolute;
        }

        h4{
            text-align: center;
            font-size: 1.8em;
            border-bottom: solid 0.1em var( --sign-color-dark );
            padding-bottom: 0.5em;
        }

    }

    // Status
    .status{
        .body{
            padding: 0 1em;

            position: relative;

            border: solid 0.8em var( --sign-color-dark );
            border-radius: 1em;
            padding: 1em;
            background-color: #ffe2bc;

            h4{
                text-align: center;
            }

            .tracker{
                display: flex;
                flex-direction: row;
                padding: 1em 0;
                
                background-color: #fff;
                border-radius: 1em;
                border: solid 0.3em var( --sign-color-dark );
        
                .status{
                    display: inline-block;
                    text-align: center;
                    background-color: black;
                    padding: 1em;
                    border-radius: 1em;
        
                }
                
                .fa-{
                    &cookie-bite{
                        font-size: 2em;
                        color: white;                
                        filter: drop-shadow( 2px 2px 0 #000 );
        
                    }
                }
        
                // Found
                .found{
                    .status{
                        background-color: green;
                    }
                    
                    .fa-{
                        &cookie-bite{
                            color: brown;                
                            filter: drop-shadow( 2px 2px 0 #000 );
            
                        }
                    }
                }
        
        
            }

        }
        

    }

    
    // After //

    // Recipe
    .ingredients{
        label{
            cursor: pointer;

        }

        input{
            cursor: pointer;            

            &[ type="checkbox" ]:checked+label{
                text-decoration: line-through;
            } 

        }

    }

}