// Dustin Reese text - Cool Effect

// Variables
$max: 8;
$time: 2s;

$width: 604px;
$center: calc( $width / 2 );

//////////////////////////////////////////////

// Animatinos

// Fade in
@keyframes fadeIn {
    0%{ opacity: 0; filter: hue-rotate(0deg); }
    80%{ opacity: 0; filter: hue-rotate(0deg); }
    100%{ opacity: 100%; filter: hue-rotate(0deg); }
}

// Rainbow
@keyframes raindowColor {
    15% { fill: var( --color-primary ); } 
    30% { fill: var( --color-secondary ); } 
    45% { fill: #6BA292; } 
    60% { fill: #35CE8D; }
    75% { fill: #BCD8B7; }
}

// Where the magic happens
.dustinreesetext{
    position: relative;

    max-width: 700px;
    padding: 0 1em;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: row;

    // SVG text
    svg{
        fill: white;
        filter: drop-shadow( 3px 5px 2px rgb(0 0 0 / 0.4) );

        // All our paths
        path{
            position: relative;
        }

        // Text
        .name{
            path{
                transform-origin: center;
                transform-box: fill-box;
                
            }                    

            @for $item from 1 through $max {
                path:nth-child( #{ $item } ){
                    animation:  #{ $time * 2 } -#{ calc( $item / $max ) }s 1 normal rotateAround,
                                $time -#{ calc( $item / $max ) }s infinite forwards reverse raindowColor,
                                $time ease-in -#{ calc( $item / $max ) }s 1 normal forwards fadeIn;

                    &:hover{
                        animation: #{ $time * 2 } -#{ calc( $item / $max ) }s 0 reverse rotateAround;
                        cursor: pointer;
    
                    }

                }

            } 

        }

        // Shadow behind text
        .shadow{
            fill: #292929;

            path{
                opacity: 0;

                transform-origin: center;
                transform-box: fill-box;

                animation: $time ease-in 2s 1 normal forwards fadeIn;

            }
            
        }

    }
    
}

@media only screen and ( max-width: 600px ) {
    .dustinreesetext {
        display: inherit;

        .dash{
            display: none;
            opacity: 0;
        }

    }
    
}