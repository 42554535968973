// SCSS for Bootstrap restyling

// Animation
.bounce:hover {
    animation-name: bounce;
    animation-timing-function: ease-in-out;
}
@keyframes bounce {
  0%   { transform: rotate(0); }
  20%  { transform: rotate(3deg); }
  70%  { transform: rotate(-3deg); }
}

// Navbar
.navbar{
    border-bottom: solid 0.1em #000;

    &-brand{
        font-size: 1.5em;
    }
}

// Button
.btn{
    border-radius: 2em;
    border: solid 0.1em #000;

    &-primary{
        background-color: var(--color-action);
        color: var(--color-action-text);
        text-transform: uppercase;
        font-weight: bolder;
    }

    &:hover{
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-name: bounce;

        border-color: white;

    }

}

// Padding
.p{
    &b-1{
        padding-bottom: 1em;
    }
    &b-2{
        padding-bottom: 2em;
    }
    &b-3{
        padding-bottom: 3em;
    }

    &t-6{
        padding-top: 6em;
    }
    &b-6{
        padding-bottom: 6em;
    }
}

// Card
.card-{
    &body{
        padding: 0;

        .card-title{
            background-color: var(--color-primary);
            color: var(--color-primary-text);
            padding: 1rem 2rem;
            font-size: 1.5em;
        }

        .card-text{            
            font-size: 1em;
            padding: 1rem 2rem;
        }

    }
}

// Modal
.modal{
    .modal-header{
        background-color: var( --color-primary );
        color: var( --color-primary-text );
    }
}

// Screen size
@media only screen and ( max-width: 600px ) {
    .card-{
        &body{    
            .card-title{
                padding: 0.5em 0.5em;

            }

        }

    }
    
}