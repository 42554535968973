// About Page

// About Content
.aboutContent{

    .nav-tabs{
        .nav-item{
            .nav-link{
                background-color: var( --color-action );
                color: var( --color-action-text );
                border: solid 1px black;
                margin-right: 1em;
                text-decoration: underline;

                &:hover{
                    color: var( --color-action-btn-hover-color );
                    background-color: var( --color-action-btn-hover-bg );
                }

                &.active{
                    background-color: white;
                    text-decoration: none;
                    border-bottom: solid 1px white;
                    color: #000;
                    cursor: inherit;
                }

                &.disabled{
                    background-color: #d1d1d1;
                    color: var( --color-action-text );
                    text-decoration: none;
                }

            }

        }

    }

    .tab-content{
        background-color: white;
        padding: 1em;
        border: solid 1px black;
    }
  
  }

  // If we're doing the full page, hide the headers and show everything
  .fullPage{
    background-color: var( --color-secondary);

    .nav{
        display: none;

    }

    .tab-pane{
        display: block;
        opacity: 1;
    }

  }