// SCSS Variables

:root {
  // // Color // // 

  // Basic
  --color-white: #fff;
  --color-black: #000;

  // Primary
  --color-primary: #42803b;  
  --color-primary-light: #42803b36;  
  --color-primary-text: #fff;

  // Secondary
  --color-secondary: #aa4eb1;
  --color-secondary-text: #000;

  // Action
  --color-action: #FF8484;
  --color-action-text: #000;

  // Action Hover
  --color-action-btn-hover-bg: #0d6efd;
  --color-action-btn-hover-color: #fff;

  // Background color
  --color-bg: var( --color-white );
  

  // // Sizing // // 
  --font-size: 16px;

}