// 404 - No Page

// Animations

// Meteor
@keyframes meteor {
    0% { transform: translate( 0, 0 ); color: red; scale: 1; }
    20% { transform: translate( 0.1em, 0 ); color: darkorange; scale: 1.01; }
    40% { transform: translate( -0.1em, 0.1em ); color: rgb(219, 83, 20); scale: 1; }
    60% { transform: translate( 0em, -0.01em ); color: darkred; scale: 0.98; }
    80% { transform: translate( -0.1em, 0 ); color: rgb(133, 0, 0); scale: 1.01; }
    100% { transform: translate( 0, 0 ); color: rgb(190, 130, 19); scale: 1; }
}

// Cloud
@keyframes cloud{
    0% { transform: translate( 0, 0 ); scale: 1; }
    10% { scale: 0.9; }
    20% { scale: 1.1; }
    30% { scale: 0.9; }
    40% { scale: 1.1; }
    50% { transform: translate( 10em, -0.1em ); scale: 0.9; }
    60% { scale: 1.1; }
    70% { scale: 0.9; }
    80% { scale: 1.1; }
    90% { scale: 0.9; }
    100%{ transform: translate( 0, 0 ); scale: 1; }
}

// Vehicle
@keyframes drive{
    0% { transform: translate( -5em, 0 );}
    100% { transform: translate( 50em, 0 );}
}


// The page
.no-page-404{
    border: solid #727272 0.5em;
    border-radius: 1em;
    position: relative;
    overflow: hidden;
    padding-top: 2em;

    background-image: linear-gradient( to bottom, #bfbfff 75%, #c0ffdc 25%);

    // Skybox
    .top{
        z-index: -5;
        margin-bottom: 1em;

        // Font Awesomes
        .fa-{

            &meteor{
                font-size: 4em;
                position: absolute;
                right: 0.5em;
                top: 0.5em;
                filter: drop-shadow( 0 0 10px #ffffff );
    
                animation-name: meteor;
                animation-duration: 0.2s;
                animation-iteration-count: infinite;
                transform-origin: center;
                animation-timing-function: linear;
    
            }

            &cloud{
                font-size: 4em;
                color: #fff;
                filter: drop-shadow( 1px 1px 10px #c2c2c2 );
    
                animation-name: cloud;
                animation-duration: 40s;
                animation-direction: alternate-reverse;
                animation-iteration-count: infinite;
                animation-timing-function: ease-in-out;
    
                &.one{
                    font-size: 1em;
                    color: #c9c9c9;
                    animation-duration: 60s;
                    animation-direction: alternate;
    
                }
    
                &.two{
                    font-size: 3em;
                    color: #f3f3f3;
                    animation-duration: 50s;
                    animation-direction: normal;
    
                }

            }
    
        }

    }

    // Forground
    .fg{
        font-size: 10em;
        z-index: 4;

        margin-left: -0.2em;

        text-wrap: nowrap;
        border-bottom: solid 0.1em brown;

        svg{
            font-size: 1.5em;
            z-index: 1;
        }

        svg:nth-child( odd ){
            font-size: 1em;
            margin-right: -0.1em;
            margin-left: -0.1em;
            z-index: -1;
        }

    }

    .carDrive{
        position: absolute;
        bottom: 0;
        left: 0;

        animation: drive;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        .fa-car-side{
            filter: drop-shadow( 0 0 1px #fff );

            color: black;
            font-size: 4em;
            
            animation: shake;
            animation-duration: 0.5s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;

        }


    }
    

}